/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
import React, { useEffect, useState } from "react";
import { addCustomer, getBusiness, updateCustomer } from "../../../service/api";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { Spinner } from "react-bootstrap";

const validationSchema = yup.object().shape({
  name: yup.string().required("Name is required."),
  desc: yup.string().required("Email is required"),
  addressLine1: yup.string().required("Address is required."),
  addressLine2: yup.string().required("Street is required."),
  gstNumber: yup.string().required("GST Number is required."),
  city: yup.string().required("City is required."),
  state: yup.string().required("State is required."),
  pinCode: yup.string().required("PIN Code is required."),
  mobile: yup
    .string()
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits.")
    .required("Mobile is required."),
  accountNumber: yup.number().required("Account Number is required."),
  bankName: yup.string().required("Bank Name is required."),
  holderName: yup.string().required("Account Name is required."),
  ifscCode: yup.string().required("IFSC is required."),
  // businessId: yup.string().required("Business is required."),
});
const AddCustomerModal = (props) => {
  let selected = props.selected;
  let getData = props.getData;
  const [business, setBusiness] = useState();
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: {
      name: "",
      desc: "",
      mobile: "",
      gstNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      holderName: "",
      // businessId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      const formdata = new FormData();
      formdata.append("name", values?.name);
      formdata.append("desc", values?.desc);
      formdata.append("mobile", values?.mobile);
      formdata.append("gstNumber", values?.gstNumber);
      let address = {
        addressLine1: values?.addressLine1,
        addressLine2: values?.addressLine2,
        state: values?.state,
        city: values?.city,
        pinCode: values?.pinCode.toString(),
      };
      formdata.append("bankName", values?.bankName);
      formdata.append("accountNumber", values?.accountNumber);
      formdata.append("ifscCode", values?.ifscCode);
      formdata.append("holderName", values?.holderName);
      formdata.append("businessId", business && business[0]?._id);

      formdata.append("address", JSON.stringify(address));
      addCustomer(formdata)
        .then((res) => {
          console.log("res--->", res);
          if (res?.status) {
            toast.success(res?.message);
            getData();
            formik.resetForm();
            document
              ?.getElementById("add-customer")
              ?.classList?.remove("show", "d-block", "modal-open");
            document
              ?.getElementsByClassName("modal-backdrop")[0]
              ?.classList?.remove("modal-backdrop");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          toast.error(e?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const formikUpdate = useFormik({
    initialValues: {
      name: "",
      desc: "",
      mobile: "",
      gstNumber: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      pinCode: "",
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      holderName: "",
      // businessId: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      const formdata = new FormData();
      selected?.name !== values?.name && formdata.append("name", values?.name);
      selected?.desc !== values?.desc && formdata.append("desc", values?.desc);
      selected?.mobile !== values?.mobile &&
        formdata.append("mobile", values?.mobile);
      selected?.gstNumber !== values?.gstNumber &&
        formdata.append("gstNumber", values?.gstNumber);
      let address = {};
      if (selected?.address?.addressLine1 !== values?.addressLine1) {
        address.addressLine1 = values?.addressLine1;
      }
      if (selected?.address?.addressLine2 !== values?.addressLine2) {
        address.addressLine2 = values?.addressLine2;
      }
      if (selected?.address?.pinCode !== values?.pinCode) {
        address.pinCode = values?.pinCode.toString();
      }
      if (selected?.address?.city !== values?.city) {
        address.city = values?.city;
      }
      if (selected?.address?.state !== values?.state) {
        address.state = values?.state;
      }
      selected?.bankDetails?.bankName !== values?.bankName &&
        formdata.append("bankName", values?.bankName);
      selected?.bankDetails?.accountNumber !== values?.accountNumber &&
        formdata.append("accountNumber", values?.accountNumber);
      selected?.bankDetails?.ifscCode !== values?.ifscCode &&
        formdata.append("ifscCode", values?.ifscCode);
      selected?.bankDetails?.holderName !== values?.holderName &&
        formdata.append("holderName", values?.holderName);
      selected?.bankDetails?.businessId !== values?.businessId &&
        formdata.append("businessId", business && business[0]?._id);

      if (
        selected?.address?.city !== values?.city ||
        selected?.address?.state !== values?.state ||
        selected?.address?.addressLine1 !== values?.addressLine1 ||
        selected?.address?.addressLine2 !== values?.addressLine2 ||
        selected?.address?.pinCode !== values?.pinCode
      ) {
        formdata.append("address", JSON.stringify(address));
      }

      updateCustomer(selected, formdata)
        .then((res) => {
          console.log("res--->", res);
          if (res?.status) {
            toast.success(res?.message);
            getData();
            formik.resetForm();
            document
              ?.getElementById("edit-customer")
              ?.classList?.remove("show", "d-block", "modal-open");
            document
              ?.getElementsByClassName("modal-backdrop")[0]
              ?.classList?.remove("modal-backdrop");
          } else {
            toast.error(res?.message);
          }
        })
        .catch((e) => {
          console.log("%c Line:167 🍖 e", "color:#3f7cff", e);
          toast.error(e?.message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  console.log("%c Line:34 🌮 business", "color:#33a5ff", formik);

  useEffect(() => {
    if (selected) {
      formikUpdate.setFieldValue("name", selected?.name);
      formikUpdate.setFieldValue("desc", selected?.desc);
      formikUpdate.setFieldValue("gstNumber", selected?.gstNumber);
      formikUpdate.setFieldValue("mobile", selected?.mobile);
      formikUpdate.setFieldValue(
        "addressLine1",
        selected?.address?.addressLine1
      );
      formikUpdate.setFieldValue(
        "addressLine2",
        selected?.address?.addressLine2
      );
      formikUpdate.setFieldValue("pinCode", selected?.address?.pinCode);
      formikUpdate.setFieldValue("city", selected?.address?.city);
      formikUpdate.setFieldValue("state", selected?.address?.state);
      formikUpdate.setFieldValue(
        "accountNumber",
        selected?.bankDetails?.accountNumber
      );
      formikUpdate.setFieldValue("bankName", selected?.bankDetails?.bankName);
      formikUpdate.setFieldValue(
        "holderName",
        selected?.bankDetails?.holderName
      );
      formikUpdate.setFieldValue("ifscCode", selected?.bankDetails?.ifscCode);
    }
  }, [selected]);
  useEffect(() => {
    if (!business) {
      getBusiness()
        .then((res) => {
          console.log("%c Line:42 🍅 res", "color:#ea7e5c", res);
          if (res?.status) {
            setBusiness(res?.data);
          }
        })
        .catch((e) => {
          console.log("%c Line:45 🍬 e", "color:#4fff4B", e);
        });
    }
  }, [business]);

  return (
    <>
      {/* Add Customer */}
      <div className="modal fade" id="add-customer">
        <div className="modal-dialog modal-lg modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <form className="form-details" onSubmit={formik.handleSubmit}>
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Add Customer</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div
                    className="modal-body custom-modal-body"
                    style={{
                      height: "80vh",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                  >
                    {/* <div className="modal-title-head people-cust-avatar">
                      <h6>Avatar</h6>
                    </div>
                    <div className="new-employee-field">
                      <div className="profile-pic-upload">
                        <div className="profile-pic">
                          <span>
                            <i
                              data-feather="plus-circle"
                              className="plus-down-add"
                            />{" "}
                            Add Image
                          </span>
                        </div>
                        <div className="mb-3">
                          <div className="image-upload mb-0">
                            <input type="file" />
                            <div className="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-row row">
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Customer Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.name &&
                              formik.touched.name &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.name && formik.touched.name
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="name"
                            id="name"
                            value={formik?.values?.name}
                            onChange={(e) => formik.handleChange(e)}
                          />{" "}
                          <div className="invalid-feedback">
                            {formik.errors.name && formik.touched.name
                              ? formik.errors.name
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              formik.errors.desc &&
                              formik.touched.desc &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.desc && formik.touched.desc
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="desc"
                            id="desc"
                            value={formik?.values?.desc}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.desc && formik.touched.desc
                              ? formik.errors.desc
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="input-blocks">
                          <label className="mb-2">
                            Mobile<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className={`form-control ${
                              formik.errors.mobile &&
                              formik.touched.mobile &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.mobile && formik.touched.mobile
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="mobile"
                            id="mobile"
                            value={formik?.values?.mobile}
                            onChange={(e) => formik.handleChange(e)}
                          />{" "}
                          <div className="invalid-feedback">
                            {formik.errors.mobile && formik.touched.mobile
                              ? formik.errors.mobile
                              : ""}
                          </div>{" "}
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            GST Number<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.gstNumber &&
                              formik.touched.gstNumber &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.gstNumber &&
                              formik.touched.gstNumber
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="gstNumber"
                            id="gstNumber"
                            value={formik?.values?.gstNumber}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.gstNumber && formik.touched.gstNumber
                              ? formik.errors.gstNumber
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.addressLine1 &&
                              formik.touched.addressLine1 &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.addressLine1 &&
                              formik.touched.addressLine1
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="addressLine1"
                            id="addressLine1"
                            value={formik?.values?.addressLine1}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.addressLine1 &&
                            formik.touched.addressLine1
                              ? formik.errors.addressLine1
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Street<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.addressLine2 &&
                              formik.touched.addressLine2 &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.addressLine2 &&
                              formik.touched.addressLine2
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="addressLine2"
                            id="addressLine2"
                            value={formik?.values?.addressLine2}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.addressLine2 &&
                            formik.touched.addressLine2
                              ? formik.errors.addressLine2
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.city &&
                              formik.touched.city &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.city && formik.touched.city
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="city"
                            id="city"
                            value={formik?.values?.city}
                            onChange={(e) => formik.handleChange(e)}
                          />{" "}
                          <div className="invalid-feedback">
                            {formik.errors.city && formik.touched.city
                              ? formik.errors.city
                              : ""}
                          </div>{" "}
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.state &&
                              formik.touched.state &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.state && formik.touched.state
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="state"
                            id="state"
                            value={formik?.values?.state}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.state && formik.touched.state
                              ? formik.errors.state
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Pin Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className={`form-control ${
                              formik.errors.pinCode &&
                              formik.touched.pinCode &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.pinCode && formik.touched.pinCode
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="pinCode"
                            id="pinCode"
                            value={formik?.values?.pinCode}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.pinCode && formik.touched.pinCode
                              ? formik.errors.pinCode
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Bank Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.bankName &&
                              formik.touched.bankName &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.bankName && formik.touched.bankName
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="bankName"
                            id="bankName"
                            value={formik?.values?.bankName}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {formik.errors.bankName && formik.touched.bankName
                              ? formik.errors.bankName
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            IFSC Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.ifscCode &&
                              formik.touched.ifscCode &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.ifscCode && formik.touched.ifscCode
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="ifscCode"
                            id="ifscCode"
                            value={formik?.values?.ifscCode}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.ifscCode && formik.touched.ifscCode
                              ? formik.errors.ifscCode
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Account Number
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className={`form-control ${
                              formik.errors.accountNumber &&
                              formik.touched.accountNumber &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.accountNumber &&
                              formik.touched.accountNumber
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="accountNumber"
                            id="accountNumber"
                            value={formik?.values?.accountNumber}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.accountNumber &&
                            formik.touched.accountNumber
                              ? formik.errors.accountNumber
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Account holder name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.errors.holderName &&
                              formik.touched.holderName &&
                              "is-invalid"
                            }`}
                            style={
                              formik.errors.holderName &&
                              formik.touched.holderName
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="holderName"
                            id="holderName"
                            value={formik?.values?.holderName}
                            onChange={(e) => formik.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formik.errors.holderName &&
                            formik.touched.holderName
                              ? formik.errors.holderName
                              : ""}
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-12">
                        <div className="mb-3 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                          />
                          <p>Maximum 60 Characters</p>
                        </div>
                      </div> */}
                    </div>
                    <div className="modal-footer-btn d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        style={{ width: "100px" }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-submit me-2 d-flex align-items-center justify-content-center"
                        style={{ width: "100px" }}
                      >
                        {loading ? (
                          <Spinner
                            style={{ height: "20px", width: "20px" }}
                            className="me-2"
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Customer */}

      {/* Edit Customer */}
      <div className="modal fade" id="edit-customer">
        <div className="modal-dialog modal-lg modal-dialog-centered custom-modal-two">
          <div className="modal-content">
            <div className="page-wrapper-new p-0">
              <form
                className="form-details"
                onSubmit={formikUpdate.handleSubmit}
              >
                <div className="content">
                  <div className="modal-header border-0 custom-modal-header">
                    <div className="page-title">
                      <h4>Edit Customer</h4>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                  <div
                    className="modal-body custom-modal-body"
                    style={{
                      height: "80vh",
                      overflowY: "scroll",
                      scrollbarWidth: "none",
                    }}
                  >
                    {/* <div className="modal-title-head people-cust-avatar">
                      <h6>Avatar</h6>
                    </div>
                    <div className="new-employee-field">
                      <div className="profile-pic-upload">
                        <div className="profile-pic">
                          <span>
                            <i
                              data-feather="plus-circle"
                              className="plus-down-add"
                            />{" "}
                            Add Image
                          </span>
                        </div>
                        <div className="mb-3">
                          <div className="image-upload mb-0">
                            <input type="file" />
                            <div className="image-uploads">
                              <h4>Change Image</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="form-row row">
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Customer Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.name &&
                              formikUpdate.touched.name &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.name &&
                              formikUpdate.touched.name
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="name"
                            id="name"
                            value={formikUpdate?.values?.name}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />{" "}
                          <div className="invalid-feedback">
                            {formikUpdate.errors.name &&
                            formikUpdate.touched.name
                              ? formikUpdate.errors.name
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              formikUpdate.errors.desc &&
                              formikUpdate.touched.desc &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.desc &&
                              formikUpdate.touched.desc
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="desc"
                            id="desc"
                            value={formikUpdate?.values?.desc}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.desc &&
                            formikUpdate.touched.desc
                              ? formikUpdate.errors.desc
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="input-blocks">
                          <label className="mb-2">
                            Mobile<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className={`form-control ${
                              formikUpdate.errors.mobile &&
                              formikUpdate.touched.mobile &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.mobile &&
                              formikUpdate.touched.mobile
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="mobile"
                            id="mobile"
                            value={formikUpdate?.values?.mobile}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />{" "}
                          <div className="invalid-feedback">
                            {formikUpdate.errors.mobile &&
                            formikUpdate.touched.mobile
                              ? formikUpdate.errors.mobile
                              : ""}
                          </div>{" "}
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            GST Number<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.gstNumber &&
                              formikUpdate.touched.gstNumber &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.gstNumber &&
                              formikUpdate.touched.gstNumber
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="gstNumber"
                            id="gstNumber"
                            value={formikUpdate?.values?.gstNumber}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.gstNumber &&
                            formikUpdate.touched.gstNumber
                              ? formikUpdate.errors.gstNumber
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12 pe-0">
                        <div className="mb-3">
                          <label className="form-label">
                            Address<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.addressLine1 &&
                              formikUpdate.touched.addressLine1 &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.addressLine1 &&
                              formikUpdate.touched.addressLine1
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="addressLine1"
                            id="addressLine1"
                            value={formikUpdate?.values?.addressLine1}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.addressLine1 &&
                            formikUpdate.touched.addressLine1
                              ? formikUpdate.errors.addressLine1
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Street<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.addressLine2 &&
                              formikUpdate.touched.addressLine2 &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.addressLine2 &&
                              formikUpdate.touched.addressLine2
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="addressLine2"
                            id="addressLine2"
                            value={formikUpdate?.values?.addressLine2}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.addressLine2 &&
                            formikUpdate.touched.addressLine2
                              ? formikUpdate.errors.addressLine2
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            City<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.city &&
                              formikUpdate.touched.city &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.city &&
                              formikUpdate.touched.city
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="city"
                            id="city"
                            value={formikUpdate?.values?.city}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />{" "}
                          <div className="invalid-feedback">
                            {formikUpdate.errors.city &&
                            formikUpdate.touched.city
                              ? formikUpdate.errors.city
                              : ""}
                          </div>{" "}
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            State<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.state &&
                              formikUpdate.touched.state &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.state &&
                              formikUpdate.touched.state
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="state"
                            id="state"
                            value={formikUpdate?.values?.state}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.state &&
                            formikUpdate.touched.state
                              ? formikUpdate.errors.state
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Pin Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className={`form-control ${
                              formikUpdate.errors.pinCode &&
                              formikUpdate.touched.pinCode &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.pinCode &&
                              formikUpdate.touched.pinCode
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="pinCode"
                            id="pinCode"
                            value={formikUpdate?.values?.pinCode}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.pinCode &&
                            formikUpdate.touched.pinCode
                              ? formikUpdate.errors.pinCode
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Bank Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.bankName &&
                              formikUpdate.touched.bankName &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.bankName &&
                              formikUpdate.touched.bankName
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="bankName"
                            id="bankName"
                            value={formikUpdate?.values?.bankName}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div
                            className="invalid-feedback"
                            style={{ display: "block" }}
                          >
                            {formikUpdate.errors.bankName &&
                            formikUpdate.touched.bankName
                              ? formikUpdate.errors.bankName
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            IFSC Code<span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.ifscCode &&
                              formikUpdate.touched.ifscCode &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.ifscCode &&
                              formikUpdate.touched.ifscCode
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="ifscCode"
                            id="ifscCode"
                            value={formikUpdate?.values?.ifscCode}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.ifscCode &&
                            formikUpdate.touched.ifscCode
                              ? formikUpdate.errors.ifscCode
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Account Number
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="number"
                            className={`form-control ${
                              formikUpdate.errors.accountNumber &&
                              formikUpdate.touched.accountNumber &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.accountNumber &&
                              formikUpdate.touched.accountNumber
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="accountNumber"
                            id="accountNumber"
                            value={formikUpdate?.values?.accountNumber}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.accountNumber &&
                            formikUpdate.touched.accountNumber
                              ? formikUpdate.errors.accountNumber
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="mb-3 col-md-6">
                        <div className="mb-3">
                          <label className="form-label">
                            Account holder name
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formikUpdate.errors.holderName &&
                              formikUpdate.touched.holderName &&
                              "is-invalid"
                            }`}
                            style={
                              formikUpdate.errors.holderName &&
                              formikUpdate.touched.holderName
                                ? { borderColor: "#dc3545" }
                                : { borderColor: "#e9edf6" }
                            }
                            name="holderName"
                            id="holderName"
                            value={formikUpdate?.values?.holderName}
                            onChange={(e) => formikUpdate.handleChange(e)}
                          />
                          <div className="invalid-feedback">
                            {formikUpdate.errors.holderName &&
                            formikUpdate.touched.holderName
                              ? formikUpdate.errors.holderName
                              : ""}
                          </div>
                        </div>
                      </div>

                      {/* <div className="col-lg-12">
                        <div className="mb-3 input-blocks">
                          <label className="form-label">Descriptions</label>
                          <textarea
                            className="form-control mb-1"
                            defaultValue={""}
                          />
                          <p>Maximum 60 Characters</p>
                        </div>
                      </div> */}
                    </div>
                    <div className="modal-footer-btn d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                        style={{ width: "100px" }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-submit me-2 d-flex align-items-center justify-content-center"
                        style={{ width: "100px" }}
                      >
                        {loading ? (
                          <Spinner
                            style={{ height: "20px", width: "20px" }}
                            className="me-2"
                          />
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Customer */}
    </>
  );
};

export default AddCustomerModal;
